import {Inject, OnInit, ElementRef} from '@angular/core';
import { Component } from '@angular/core';
import { AppBaseComponent } from '@src/base-components/app-base.component';
import { IAuthService } from '@src/auth/auth.service.interface';
import { WindowEventsDispatcher } from '@src/core/WindowEventsDispatcher';
import { ConfigService } from '@src/services/layout/config.service';
import { FiltersPages } from '@src/model/config/filtersPages';
import { environment } from '@src/environments/environment';
import { EnvironmentNames } from '@src/environments/environments.name';

@Component({
  selector: 'app-root',
  templateUrl: './alpina.component.html',
  styleUrls: ['./alpina.component.scss']
})
export class AlpinaComponent extends AppBaseComponent implements OnInit {
    orientationLayout: string;
    filterOptions: FiltersPages;
    config: any = {};

    // Set toastr container ref configuration for toastr positioning on screen
    constructor(
        @Inject('AuthService') private authService : IAuthService,
        private elementRef: ElementRef,
        private configService: ConfigService) {
      super();
    }

  ngOnInit() {
    this.config = this.configService.templateConf;
    if (environment.envName === EnvironmentNames.prod) {
      this.filterOptions = new FiltersPages(false, true, true, true);
    } else {
      this.filterOptions = new FiltersPages(false, true, true,true);
    }
    this.configService.displayWarningMessage(false);
    this.orientationLayout = this.configService.templateConf.layout.dir;
    this.configService.setNewFilters(this.filterOptions);
    this.configService.setVersionWebsite(false);
    this.configService.setPrepairedClient(false);
    // sidebar toggle event listner
    let elem = this.elementRef.nativeElement.querySelector('#sidebarToggle');
    if (elem){
      elem.addEventListener('click', () => {WindowEventsDispatcher.fireDelayedResizeEvent(300); });
    }
  }

  IsInternalPage() {
    return window.location.pathname !== '/AfterSalesService/CustomerWarranties';
  }
}

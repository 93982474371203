import { Component, Inject, OnChanges, EventEmitter, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesService } from '@src/services/sales/sales.service';
import { Sale } from '@src/model/sale';
import { SaleDetails } from '@src/model/saleDetails';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { ProductItem } from '@src/model/productItem';
import { AppSettings } from '@src/core/AppSettings';
import { IAuthService } from '@src/auth/auth.service.interface';
import * as moment from 'moment';
import { UserRolesEnum } from '@src/model/userRoles';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalReturnSaleComponent } from './return-sale/modal-return-sale.component';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@src/model/user';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { UserProfile } from '@src/auth/user-profile';
import { Market } from '@src/model/market';
import { UsersService } from '@src/services/users/users.service';
import { MarketsService } from '@src/services/markets/markets.service';
import { WarrantyReturnSettings } from '@src/model/config/WarrantyReturnSettings';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { ImportedFileDetailsComponent } from '@src/base-components/management/imported-file-details/imported-file-details.component';
import { ModalCancelExtensionComponent } from './return-extension/modal-return-extension.component';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-sale-details',
    templateUrl: './sale-details.component.html',
    styleUrls: ['./sale-details.component.scss']
})

export class SaleDetailsComponent implements OnChanges, OnInit {
    saleDetails: SaleDetails = new SaleDetails();
    saleWarranty: Warranty = new Warranty();
    warranties: Warranty[] = [];
    operations: WarrantyOperation[] = [];
    productItem: ProductItem = new ProductItem();
    sale: Sale = new Sale();
    isLoaded = false;
    editEnabled = false;
    returnSaleEnabled = false;
    correctProductEnabled = false;
    correctWarrantyEnabled = false;
    isSearchResult = false;
    hasRepairWarranties = false;
    loggedUser : UserProfile;;
    marketSale: Market;
    validUser: boolean;
    validRefurbishUser: boolean = false;
    isBreitling: boolean = false;
    warrantyReturnSettings: WarrantyReturnSettings;
    protected ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
    };

    constructor(@Inject('AuthService')
        protected authService: IAuthService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected salesService: SalesService,
        protected modalService: NgbModal,
        protected appSettings: AppSettings,
        protected translateService: TranslateService,
        protected usersService: UsersService,
        protected marketsService: MarketsService,
        protected warrantiesService: WarrantiesService
    ) {
        this.loggedUser = this.authService.getCurrentUserInstant();
        this.getWarrantyReturnSettings();
        this.isBreitling = environment.clientName == "breitling" ? false : true;
    }

    ngOnInit() {
        this.isSearchResult = this.router.url.indexOf('search') !== -1;       

        this.route.params.subscribe(params => {
            const saleId = +params['id'];
            if (saleId) {
                this.getSaleDetails(saleId, this.isSearchResult);
            }
        });        
    }

    ngOnChanges() {
        if (this.sale.isReturned) {
            this.returnSaleEnabled = false;
        } else {
            this.returnSaleEnabled = true;
        }
    }

    getWarrantyReturnSettings() {
        this.warrantiesService.getWarrantyReturnSettings()
        .subscribe(r =>{
            this.warrantyReturnSettings = r;
            if (this.warrantyReturnSettings && this.warrantyReturnSettings.enabled) {
                if(this.loggedUser.isInRoles(this.warrantyReturnSettings.warrantyReturnAuthorizedRoles)){
                    this.appSettings.MaximumDaysAllowedForWarrantyReturnExtended = this.warrantyReturnSettings.maximumDaysForWarrantyReturnExtended;
                }
                this.appSettings.MaximumDaysAllowedForWarrantyReturn = this.warrantyReturnSettings.maximumDaysForWarrantyReturn;
            }
        });
      }

    setHasRepairWarranties(option: boolean) {
        this.hasRepairWarranties = option;
    }

    cancelSale() {
        const modalRef = this.modalService.open(ModalReturnSaleComponent, this.ngbModalOptions);
        modalRef.componentInstance.warranties =  this.warranties;
        modalRef.componentInstance.sale = this.sale;
        modalRef.componentInstance.operations = this.operations;
        modalRef.componentInstance.editEnabled = this.editEnabled;
        modalRef.componentInstance.returnSaleEnabled = this.returnSaleEnabled;
        modalRef.componentInstance.refurbished = false;
        modalRef.componentInstance.correctWarrantyEnabled = this.correctWarrantyEnabled;
        modalRef.componentInstance.refreshWarranties.subscribe((warranties) => {
            this.warranties = warranties;
        });
        modalRef.componentInstance.refreshReturnSaleButton.subscribe((returnSaleEnabled) => {
            this.returnSaleEnabled = returnSaleEnabled;
        });
    }

    cancelWarrantyExtension(){
        const modalRef = this.modalService.open(ModalCancelExtensionComponent, this.ngbModalOptions);
        modalRef.componentInstance.warranties =  this.warranties;  
        modalRef.componentInstance.refreshWarranties.subscribe((warranties) => {
            this.warranties = warranties;
        });   
      }

    getSaleDetails(saleId: number, isSearch: boolean): void {
        this.salesService.getSaleDetails(saleId, isSearch)
            .subscribe((sd) => {
                this.saleDetails = sd;

                this.saleDetails.warranty.isExpired = new Date() > new Date(this.saleDetails.warranty.warrantyEndDate);
                this.saleDetails.warranty.type = 'sale';
                this.saleDetails.warranty.seller = sd.warranty.seller;
                this.saleDetails.warranty.sellerId = sd.warranty.sellerId;
                this.saleDetails.warranty.retailer = sd.sale.retailer;
                this.saleDetails.warranty.retailerId = sd.sale.retailer.id;

                this.warranties = [this.saleDetails.warranty];
                this.saleWarranty = this.saleDetails.warranty;
                this.operations = sd.warranty.warrantyOperations;

                this.productItem = sd.productItem || new ProductItem();
                this.productItem.serialNumber = this.saleDetails.sale.serialNumber;

                this.sale = sd.sale;
                this.sale.isReturned = sd.warranty.isReturned;
                this.isLoaded = true;
                this.correctWarrantyEnabled = this.editEnabled = !this.sale.isReturned;
                this.correctProductEnabled = this.editEnabled && !this.appSettings.PrePairedWarrantyCards;
                
                this.returnSaleEnabled = moment.utc(sd.warranty.warrantyActivationDate).add(this.appSettings.MaximumDaysAllowedForWarrantyReturn+1, 'day').isAfter();

                this.validateUserReturnButton();
            });
    }

    setMessageTooltipButton() : string {
        return this.returnSaleEnabled ? '' : this.translateService.instant('Components.Sales.warning-return-sale',
        { daysDuration: this.appSettings.MaximumDaysAllowedForWarrantyReturn}) ;
    }

    enableReturnSaleButton() : boolean {
        return !this.returnSaleEnabled;
    }
    refreshOperationList( operations: WarrantyOperation[]) {
        this.operations = operations;
    }

    protected validateUserReturnButton() {
        const currentUser = this.authService.getCurrentUserInstant();
        this.usersService.getUser(currentUser.sub).subscribe(user => {
             if (user == undefined ) {
                return;
            }
            if (user.roles[0].name == UserRolesEnum.ADMIN || user.roles[0].name == UserRolesEnum.MARKET_ADMIN){
                this.validRefurbishUser = true;
            }
            if((user.roles[0].name === UserRolesEnum.ADMIN))
            {
                this.validUser = true;
            }
            else if (user.roles[0].name === UserRolesEnum.AGENT || (user.roles[0].name === UserRolesEnum.MARKET_ADMIN)) {
                const markets = user.markets;

                markets.forEach(market => {
                    if (this.validUser === false) {
                        this.validUser = this.sale.retailer.marketId === market.id ? true : false;
                    }
                    this.marketsService.getChildrenMarket(market.id).subscribe(children => {
                            if (children.length > 0) {
                                const childrenId = children.map(c => c.id);
                                const foundMarket = childrenId.find(cId => this.sale.retailer.marketId === cId);
                                if (foundMarket !== undefined) {
                                    this.validUser = true;
                                }
                            }
                        }
                    );
                });
            }
            else if (user.roles[0].name === UserRolesEnum.RETAILER || user.roles[0].name === UserRolesEnum.MASTER_RETAILER || user.roles[0].name === UserRolesEnum.SELLER || user.roles[0].name === UserRolesEnum.GENERICSELLER) {
                const retailers = user.retailers;
                retailers.forEach(retailer => {
                    if (retailer.id === this.sale.retailer.id) {
                        this.validUser = true;
                    }
                });
            }
            else
            {
                this.validUser = false;
            }
        });
    }

    hideReturnButton() : boolean {
        return !this.sale.isLegacy && !this.sale.isReturned && this.validUser;
    }
    
    hideReturnRefurbishedButton() : boolean { 
        return !this.sale.isLegacy && !this.sale.isReturned && this.validRefurbishUser;
    }
}

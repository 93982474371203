import { ChangeDetectorRef, Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { SalesComponent } from '@src/base-components/sales/sales.component';
import { ConfigService } from '@src/services/layout/config.service';
import { SalesService } from '@src/services/sales/sales.service';

@Component({
    //selector: 'app-sales-frederique', 
    templateUrl: './salesAlpina.component.html',  
    styleUrls: ['../../../base-components/sales/sales.component.scss']
})
export class SalesAlpinaComponent extends SalesComponent 
{
    constructor(
        @Inject('AuthService') 
        authService: IAuthService,   
        protected router: Router,
        protected route: ActivatedRoute,
        protected salesService: SalesService,
        protected translateService: TranslateService,
        protected configService: ConfigService,
        protected resolver: ComponentFactoryResolver,
        protected cdref: ChangeDetectorRef
    ) {
        super(authService, router, route, salesService, translateService, configService, resolver, cdref);
    }
 }
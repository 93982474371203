import { Component, Input, EventEmitter, Inject, Output, OnInit } from '@angular/core';
import { RepairWarranty } from '@src/model/repairWarranty';
import { Warranty } from '@src/model/warranty/warranty';
import { AppSettings } from '@src/core/AppSettings';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { WarrantyExtension } from '@src/model/warranty/warrantyExtension';
import { WarrantyExtensionType, WarrantyExtensionTypeDescription } from '@src/model/Enums/warrantyExtensionType';
import { WarrantyExtensionsService } from '@src/services/warranty/warrantyextensions.service';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-create-extension',
    templateUrl: './create-extension.component.html'
})
export class CreateExtensionComponent implements OnInit {
    @Input() selectedRepairWarranty: RepairWarranty;
    @Input() selectedWarranty: Warranty;
    @Input() extensionType: WarrantyExtensionType;

    @Output() createdExtension = new EventEmitter<WarrantyExtension>();

    calendarModel: NgbDateStruct;
    loggedUser: UserProfile;
    comments: string;
    newExtension: WarrantyExtension = new WarrantyExtension();
    processing: boolean;
    warrantyExtensionPeriod = [ 12, 24, 36, 48 ];

    constructor(@Inject('AuthService')
        private authService: IAuthService,
        private appSettings: AppSettings,
        private warrantyExtensionsService: WarrantyExtensionsService ) {}

    ngOnInit() {
        this.loggedUser = this.authService.getCurrentUserInstant();
        this.processing = false;
        this.newExtension.durationMonths = 12;
    }

    createExtension(event) {
        if (event.detail > 1) {
            return;
        }

        this.processing = true;

        this.newExtension.extensionType = this.extensionType;
        this.newExtension.warrantyId = this.selectedWarranty ? this.selectedWarranty.id : null;
        this.newExtension.repairWarrantyId = this.selectedRepairWarranty ? this.selectedRepairWarranty.id : null;
        this.newExtension.durationMonths = +this.newExtension.durationMonths;

        this.warrantyExtensionsService.addExtension(this.newExtension).subscribe(e => { this.createdExtension.emit(e); });
    }

    setExtensionType() : string {
        if (this.extensionType === WarrantyExtensionType.saleWarrantyExtension) {
            return WarrantyExtensionTypeDescription.saleWarrantyExtension;
        } else if (this.extensionType === WarrantyExtensionType.repairWarrantyExtension) {
            return WarrantyExtensionTypeDescription.repairWarrantyExtension;
        }
    }

    isSaleExtension() : boolean {
        return this.extensionType === WarrantyExtensionType.saleWarrantyExtension;
    }

    isRepairExtension() : boolean {
        return this.extensionType === WarrantyExtensionType.repairWarrantyExtension;
    }

    disableD2CExtension() : boolean {
        return this.selectedWarranty && this.selectedWarranty.warrantyExtensions && this.selectedWarranty.warrantyExtensions.find(x => x.d2CExtension) != null;
    }

    isTag() : boolean {
        if(environment.clientName === 'tag'){
            return true;
        }
        return false;
    }

    isBreitling() : boolean {
        if(environment.clientName === 'breitling'){
            return true;
        }
        return false;
    }
}
export class SalesParameters {
    from: Date;
    to: Date;
    marketId?: number;
    retailerId?: number;
    businessGroup?: string;
    productSku?: string;
    serialNumber: string;
    warrantyCard?: string;
    operationsType?: number[] = [];
    isFake?: boolean;
    hasD2CExtension?: boolean;
    isDuplicate?: boolean;
    selectedTimeRange: string;
    isWarrantyExtension?: boolean;
}
import { Market } from './market';

export class Retailer {
    constructor(){
        this.market = new Market();
    }
    isActive: boolean;
    hasCpo: boolean;
    name: string;
    code: string;
    city: string;
    isoCountry: string;
    address: string;
    addressIndications: string;
    zip: string;
    status: string;
    id: number;
    longitude: string;
    latitude: string;
    market: Market;
    marketId?: number;
    channelId?: number;
    nameRomanized: string;
    cityNameRomanized: string;
    locale: string;
    commercialGroupName: string;
    externalPosId? : string;
    posStatus?: string;
    posCluster?: string;
    posReason?: string;
    posOpeningDate?: string;
    posClosingDate?: string;
}

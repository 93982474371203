import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Warranty } from '../../model/warranty/warranty';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';
import { WarrantyUpdateWithComment } from '../../model/warranty/WarrantyUpdateWithComment';
import { WarrantyExtension } from '@src/model/warranty/warrantyExtension';

@Injectable()
export class WarrantyExtensionsService extends BaseService {
  private baseUrl = environment.apiBaseUrl + '/api/warrantyextensions';

  constructor(injector: Injector) {
    super(injector);
  }
  addExtension(extension: WarrantyExtension): Observable<WarrantyExtension> {
    const url = `${this.baseUrl}`;
    return this.post<WarrantyExtension>(url, extension);
  }

  getWarrantiesByWarrantyId(warrantyId: number): Observable<WarrantyExtension[]> {
    const url = `${this.baseUrl}?warrantyId=${warrantyId}`;
    return this.get<WarrantyExtension[]>(url);
  }

  cancelExtension(id: number): Observable<boolean> {
    const url = `${this.baseUrl}/${id}`;
    return this.post<boolean>(url, null);
  }
}

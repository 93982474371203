import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Retailer } from '@src/model/retailer';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Channel } from '@src/model/channel';

@Component({
    selector: 'app-retailer-details-info-base',
    templateUrl: './retailer-details-info.component.html',
    styleUrls: ['./retailer-details-info.component.scss']
})

export class RetailerDetailsInfoComponent {
    @Input() editEnabled = false;
    @Input() showCpo = false;
    @Input() retailer: Retailer = new Retailer();
    @Output() updateRetailer = new EventEmitter<Retailer>();
    @Output() updateEdition = new EventEmitter<boolean>();
    

    showPassword: boolean;
    inEdition: boolean;

    viewPassword() {
        this.showPassword = !this.showPassword;
    }

    edit() {
        this.inEdition = true;
        this.updateEdition.emit(this.inEdition);
    }

    cancelEdit() {
        this.inEdition = !this.inEdition;
        this.updateEdition.emit(this.inEdition);
    }

    saveChanges() {
        this.updateRetailer.emit(this.retailer);
        this.inEdition = false;
    }
}
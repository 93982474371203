import { Component, ViewChild } from '@angular/core';
import { SaleDetailsCorrectionFindProductComponent } from '@src/base-components/sales/sale-details/product/correction/sale-details-correction-find-product.component';
import { SaleDetailsCorrectionBaseComponent } from '@src/base-components/sales/sale-details/product/correction/sale-details-correction-base.component';

@Component({
    selector: 'app-sale-details-correction',
    templateUrl: './sale-details-correction.component.html',
    styleUrls: ['./sale-details-correction.component.scss']
})

export class SaleDetailsCorrectionComponent extends SaleDetailsCorrectionBaseComponent {

    @ViewChild(SaleDetailsCorrectionFindProductComponent) correctionFindProductComponent: SaleDetailsCorrectionFindProductComponent;

    resetToStep0() {
        this.correctionFindProductComponent.reset();
    }
}

import {Retailer} from './retailer';
import {User} from './user';

export class Sale {
    constructor(){
        this.retailer = new Retailer();
    }
    id: number;
    date: Date;
    endDate: Date;
    retailerName: string;
    warrantyCardCode: string;
    productSku: string;
    productName: string;
    productItemId: number;
    serialNumber: string;
    retailer: Retailer;
    seller: User;
    createdOn: Date;
    updatedOn: Date;
    isReturned: boolean;
    isFake: boolean;
    isCpo: boolean;
    hasBlockchainCertificate: boolean;
    isLegacy: boolean;
    isDuplicate : boolean;
    warrantyType: string;
    warrantyId?: number;
    isValid: boolean;
    extensionStartDate?: Date;
}


import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ProductsService } from '@src/services/products/products.service';

import { ProductItem } from '@src/model/productItem';
import { Product } from '@src/model/product';


@Component({
    selector: 'app-wizard-find-not-serializable-product',
    templateUrl: './wizard-find-not-serializable-product.html'
})
export class WizardFindNotSerializableProductComponent {
    @Output() onselectProduct = new EventEmitter<ProductItem>();
    products: Product[];
    sku: string;

    constructor(private productsService: ProductsService) { }

    getProductBySku(sku: string): void {
        this.sku = sku;
        this.productsService.getProductBySKU(sku, false)
            .subscribe(
                products => this.products = products
            );
    }
    getProductBySkuKeyDonw(event, sku: string): void {
        if (event.keyCode === 13) {
            this.getProductBySku(sku);
        }
    }
    selectProduct(product: Product): void {
        const productItem = new ProductItem();
        productItem.product = product;
        this.onselectProduct.emit(productItem);
    }

    reset(): void {
        this.clean();
    }

    private clean(): void {
        this.products = undefined;
    }


}

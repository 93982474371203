import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { UserRolesEnum } from '@src/model/userRoles';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { User } from '@src/model/user';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { RepairsService } from '@src/services/repairs/repairs.service';
import { RepairWarranty } from '@src/model/repairWarranty';
import { Retailer } from '@src/model/retailer';
import { WarrantyExtension } from '@src/model/warranty/warrantyExtension';
import { WarrantyExtensionType } from '@src/model/Enums/warrantyExtensionType';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-sale-details-warranties-base',
    templateUrl: './sale-details-warranties.component.html'
})

export class SaleDetailsWarrantiesBaseComponent extends NgxDatatableBase implements OnInit {
    @Output() refreshOperationList = new EventEmitter<WarrantyOperation[]>();
    @Output() refreshWarrantiesExtension = new EventEmitter<WarrantyOperation[]>();
    @Output() hasRepairWarranties = new EventEmitter<boolean>();
    @Input() warranties: Warranty[];
    @Input() editEnabled = false;
    @Input() isSearchResult: boolean;
    inEdition: boolean;
    currentUser: UserProfile;
    repairWarranties: RepairWarranty[] = [];
    virtualRetailer = new Retailer();
    viewExtendWarranties = false;
    marketsAllowedToExtend = ['THJAP', '0071', '71', 'JP', 'CN01', 'CN', 'THCHN', 'RU', 'RU01', 'Russia', 'THRUS', 'RU2'];

    constructor(@Inject('AuthService') private authService: IAuthService,
        private retailersService: RetailersService,
        private warrantiesService: WarrantiesService,
        private repairWarrantiesService: RepairsService,
        private translateService: TranslateService) {
        super(translateService);
        this.authService.getCurrentUser().subscribe(v => this.currentUser = v);
    }

    ngOnInit() {
        this.repairWarrantiesService.getRepairWarrantiesByWarrantyId(this.warranties[0].id).subscribe(repairs => {
            if (repairs.length === 0) {
                this.repairWarranties = [];
                this.hasRepairWarranties.emit(false);
            } else {
                repairs.forEach(repair => {
                    repair.isExpired = new Date() > repair.endDate;
                });
                this.repairWarranties = repairs;
                this.hasRepairWarranties.emit(true);
            }
        });

        this.retailersService.getRepairRetailer().subscribe(repairRetailer => {
            this.virtualRetailer = repairRetailer;
        })
    }

    edit() {
        this.inEdition = true;
        if (this.warranties && this.warranties.length > 0
            && (!this.warranties[0].possibleSellers || this.warranties[0].possibleSellers.length === 0)) {

            this.retailersService.getRetailerUsers(this.warranties[0].retailerId
            ).subscribe(sellers => {
                this.warranties.forEach(w => {
                    w.possibleSellers = sellers;
                    if (sellers.filter(s => s.id === this.currentUser.sub).length === 0) {
                        const user = new User();
                        user.id = this.currentUser.sub;
                        user.firstName = this.currentUser['given_name'];
                        user.lastName = this.currentUser['family_name'];
                        sellers.unshift(user);
                    }
                    w.possibleSellers = sellers;
                });
            });
        }
    }
    cancel() {
        this.inEdition = false;
    }
    updateWarranty() {
        this.warranties.forEach(w =>
            this.warrantiesService.updateWarrantyWithComment({ comment: '', warranty: w, refurbished: false })
                .subscribe((wr) => {
                    this.inEdition = false;
                    if (wr) {
                        this.refreshOperationList.emit(wr.warrantyOperations);
                    }
                })
        );
    }

    changeSeller({ warranty, sellerId }: { warranty: Warranty, sellerId: string }) {
        warranty.seller = warranty.possibleSellers.filter(s => s.id === sellerId)[0] || warranty.seller;
        warranty.sellerId = warranty.seller.id;
    }

    extendWarranties() {
        this.viewExtendWarranties = true;
    }

    canExtendWarranties(): boolean {
        if(environment.clientName === 'frederique' || environment.clientName === 'alpina' || environment.clientName === 'hmoser'){
            return false;
        }
        if (this.viewExtendWarranties || this.warranties[0].isReturned) {
            return false;
        }
        if (this.currentUser.role[0] === UserRolesEnum.ADMIN) {
            return true;
        }
        if(this.currentUser.role[0] === UserRolesEnum.MARKET_ADMIN){
            if(environment.clientName === 'breitling'){
                return true;
            }
        }
        if (this.currentUser.role[0] === UserRolesEnum.MARKET_ADMIN && this.currentUser.markets.some(userMarket => this.marketsAllowedToExtend.includes(userMarket))) {
            return true;
        }
        return false;
    }

    cancelExtend() {
        this.viewExtendWarranties = false;
    }

    closeWizard(event: WarrantyExtension) {
        this.viewExtendWarranties = false;
        if (event.extensionType === WarrantyExtensionType.saleWarrantyExtension) {
            const warranty = this.warranties.find(w => w.id === event.warrantyId);

            warranty.warrantyExtensions.push(event);
        } else if (event.extensionType === WarrantyExtensionType.repairWarrantyExtension) {
            const repair = this.repairWarranties.find(r => r.id === event.repairWarrantyId);

            repair.warrantyExtensions.push(event);
        }
    }

}

import { Component, ViewChild, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { RepairWarranty } from '@src/model/repairWarranty';
import { WarrantyInfo } from '@src/model/warranty/warrantyInfo';
import { WizardComponent } from 'angular-archwizard';
import { ListWarrantiesComponent } from './steps/list-warranties.component';
import { WarrantyExtensionType } from '@src/model/Enums/warrantyExtensionType';
import { WarrantyExtension } from '@src/model/warranty/warrantyExtension';
import { environment } from '@src/environments/environment';

@Component({
    selector: 'app-extend-warranties',
    templateUrl: './extend-warranties.component.html',
    styleUrls: ['./extend-warranties.component.scss']
})

export class ExtendWarrantiesComponent implements OnInit {

    @Input() warranties: Warranty[];
    @Input() repairWarranties: RepairWarranty[];
    @Output() createdExtension = new EventEmitter<WarrantyExtension>();

    modelWarranties: WarrantyInfo[] = [];
    selectedWarranty: Warranty;
    selectedRepairWarranty: RepairWarranty;
    typeExtension: WarrantyExtensionType;

    @ViewChild(WizardComponent) wizard: WizardComponent;
    @ViewChild(ListWarrantiesComponent) listWarrantiesComponent: ListWarrantiesComponent;

    ngOnInit() {
        if (this.warranties.length > 0) {
            this.warranties.forEach( w => {
                if ((environment.clientName && (environment.clientName === 'tag' || environment.clientName === 'breitling')) || !w.isExpired) {
                    const wm = new WarrantyInfo();
                    wm.id = w.id;
                    wm.type = WarrantyExtensionType.saleWarrantyExtension;
                    wm.data = w.warrantyCard.code;
                    wm.startDate = w.warrantyActivationDate;
                    wm.endDate = w.warrantyEndDate;
    
                    this.modelWarranties.push(wm);
                }
            });
        }

        if (this.repairWarranties.length > 0) {
            this.repairWarranties.forEach( r => {
                if ((environment.clientName && (environment.clientName === 'tag'|| environment.clientName === 'breitling')) || !r.isExpired) {
                    const wm = new WarrantyInfo();
                    wm.id = r.id;
                    wm.type = WarrantyExtensionType.repairWarrantyExtension;
                    wm.data = r.repairType.name;
                    wm.startDate = r.startDate;
                    wm.endDate = r.endDate;
    
                    this.modelWarranties.push(wm);
                }
            });
        }
    }

    selectWarranty(warranty: WarrantyInfo) {
        if (warranty.type === WarrantyExtensionType.saleWarrantyExtension) {
            this.selectedWarranty = this.warranties.find(w => w.id === warranty.id);
        } else if (warranty.type === WarrantyExtensionType.repairWarrantyExtension) {
            this.selectedRepairWarranty = this.repairWarranties.find(r => r.id === warranty.id);
        }
        this.typeExtension = warranty.type;
        this.wizard.navigation.goToNextStep();
    }

    getCreatedExtension(event) {
        if (event) {
            this.createdExtension.emit(event);
        }
    }
}

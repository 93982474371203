import { SalesComponent } from './sales.component';
import { SaleDetailsComponent } from './sale-details/sale-details.component';
import { SaleDetailsProductComponent } from './sale-details/product/sale-details-product.component';
import { SaleDetailsProductInfoComponent } from './sale-details/product/info/sale-details-product-info.component';
import { SaleDetailsOperationsComponent } from './sale-details/operations/sale-details-operations.component';
import { SaleDetailsWarrantiesBaseComponent } from './sale-details/warranties/sale-details-warranties.component';
import { SaleDetailsCorrectionBaseComponent } from './sale-details/product/correction/sale-details-correction-base.component';
import { SaleDetailsCorrectionConfirmationComponent } from './sale-details/product/correction/sale-details-correction-confirmation.component';
import { SaleDetailsCorrectionFindProductComponent } from './sale-details/product/correction/sale-details-correction-find-product.component';
import { SaleDetailsCorrectionSelectTypeComponent } from './sale-details/product/correction/sale-details-correction-select-type.component';
import { SaleDetailsOperationsDetailComponent } from './sale-details/operations/sale-details-operations-detail.component';
import { WarrantiesTableComponent } from './sale-details/warranties/warranties-table/warranties-table.component';
import { RepairWarrantiesTableComponent } from './sale-details/warranties/repair-warranties-table/repair-warranties-table.component';
import { SalesFilterComponent } from './filter/sales-filter.component';
import { ExtendWarrantiesComponent } from './sale-details/warranties/extend-warranties/extend-warranties.component';
import { ListWarrantiesComponent } from './sale-details/warranties/extend-warranties/steps/list-warranties.component';
import { CreateExtensionComponent } from './sale-details/warranties/extend-warranties/steps/create-extension.component';
import { SalesFilterOrisComponent } from '../../clients/oris/sales/filter/sales-filter.component';
import { SalesFrederiqueComponent } from '@src/clients/frederique/sales/salesFrederique.component';
import { SalesAlpinaComponent } from '@src/clients/alpina/sales/salesAlpina.component';

export const SalesComponents = [
    SalesComponent,
    SaleDetailsComponent,
    SaleDetailsProductComponent,
    SaleDetailsProductInfoComponent,
    SaleDetailsOperationsComponent,
    SaleDetailsOperationsDetailComponent,
    SaleDetailsWarrantiesBaseComponent,
    SaleDetailsCorrectionBaseComponent,
    SaleDetailsCorrectionConfirmationComponent,
    SaleDetailsCorrectionFindProductComponent,
    SaleDetailsCorrectionSelectTypeComponent,
    WarrantiesTableComponent,
    RepairWarrantiesTableComponent,
    SalesFilterComponent,
    SalesFilterOrisComponent,
    ExtendWarrantiesComponent,
    ListWarrantiesComponent,
    CreateExtensionComponent,
    SalesFrederiqueComponent,
    SalesAlpinaComponent
];
import { Component, Input, Output, EventEmitter, Inject, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { PagedTableModel } from '@src/shared/tables/paged-table-model';
import { InventoryProductItem } from '@src/model/inventoryProductItem';
import { ConfigService } from '@src/services/layout/config.service';
import { InventoriesService } from '@src/services/inventories/inventories.service';
import { ActivatedRoute } from '@angular/router';
import { DownloadFileService } from '@src/services/files/downloadfile.service';
import { UserRolesEnum } from '@src/model/userRoles';
import { Inventory } from "@src/model/inventory";
import { IAuthService } from '@src/auth/auth.service.interface';
import { ToastrService } from 'ngx-toastr';  
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentSerialNumberItemsTableComponent } from './shipment-serial-number/shipment-serial-number.component';
import { UsersService } from '@src/services/users/users.service';
import { Retailer } from '@src/model/retailer';
import { MarketsService } from '@src/services/markets/markets.service';
import { Market } from '@src/model/market';

@Component({
    selector: 'app-inventory-details-items-table',
    templateUrl: './inventory-details-items-table.component.html',
    styleUrls: ['./inventory-details-items-table.component.scss']
})
export class InventoryDetailsItemsTableComponent extends NgxDatatableBase implements OnInit {
    @Input() items: InventoryProductItem[];
    @Input() inventory: Inventory;
    showWarrantyCode: boolean;
    model = new PagedTableModel();
    layoutDirection: string;
    pagerLeftArrowIcon: string;
    pagerRightArrowIcon: string;
    pagerPreviousIcon: string;
    pagerNextIcon: string;
    isAddingSerial = false;
    isDeletingSerial = false;
    isLastInventory = true;
    isShipmentCalled = false;
    retailerCode : string;
    inventoryId : number;
    isUsMarketAndRole : boolean;
    userRetailers : Retailer[];
    userMarkets : Market[];
    isNotTag = true;
    @Input() editEnabled = true;
    @Input() showBlockChainIcon = false;
    serial: Array<any> = [];

    protected ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size : 'lg'

    };
    constructor(
        private router: Router,
        private toastr: ToastrService,
        @Inject('AuthService')
        private authService: IAuthService,
        protected modalService: NgbModal,
        private configService: ConfigService,
        private translateService: TranslateService,
        private inventoriesService: InventoriesService,
        private marketService : MarketsService,
        private userService: UsersService,
        private route: ActivatedRoute,
        private downloadFileService: DownloadFileService) {
        super(translateService);
        
        debugger
        // this.showWarrantyCode = this.configService.templateConf.isPrepairedClient;
        if(environment.clientName == "oris"
            || environment.clientName == "breitling"
            || environment.clientName == "tag"
            || environment.clientName == "frederique"
            || environment.clientName == "alpina") {
          if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN, UserRolesEnum.MASTER_RETAILER, UserRolesEnum.RETAILER])){
            
            if(environment.clientName == "tag" && !this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN])){
                this.editEnabled = true;
            }
            else{
                this.editEnabled = false;
            }
            this.isUsMarketAndRole = environment.clientName == "breitling" ? true : false;
            
            // if(environment.clientName == "breitling"){

            //     this.isUsMarketAndRole = true;
                
            //     if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.MARKET_ADMIN])){

            //         if(!this.authService.getCurrentUserInstant().IsUsMarket()){


            //             // let isUsRetailer = false;

            //             // userService.getMyUser().subscribe(data => { 
            //             //     if (data.retailers){
            //             //         this.userRetailers = data.retailers;
            //             //         this.userRetailers.forEach(element => {
            //             //             if(element.market.code == 'US'){
            //             //                 isUsRetailer = true;
            //             //                 return;
            //             //             }
            //             //         });
            //             //     }
            //             // });
                        

            //             this.isUsMarketAndRole = false;
            //         }
            //         else{
            //             this.isUsMarketAndRole = true;
            //         }
            //     }
            //     else if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN])){
            //         this.isUsMarketAndRole = true;
            //     }
            //     else if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.MASTER_RETAILER]) || this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.RETAILER])){                        

            //             userService.getMyUser().subscribe(data => { 
            //                 if (data.retailers){
            //                     this.userRetailers = data.retailers;
            //                     this.userRetailers.forEach(element => {
            //                         if(element.isoCountry === "US"){
            //                             this.isUsMarketAndRole = true;
            //                             return;
            //                         }
            //                     });
            //                 }
            //             });
            //     }                
            // }
            // else{
            //     this.isUsMarketAndRole = false;
            // }
          }
        } else {
          if(this.authService.getCurrentUserInstant().isInRoles([UserRolesEnum.ADMIN, UserRolesEnum.MARKET_ADMIN])){
            this.editEnabled = false;
            this.isUsMarketAndRole = false;
          }
        }
        if(environment.clientName == "tag"){
            this.isNotTag = false;
        }
    }

    ngOnInit() {
        this.retailerCode = this.inventory.retailer.code;
        this.inventoryId = this.inventory.id;
        this.model.rows = this.items;
        if (environment.clientName == "breitling" && this.inventory.isLast){
            this.isLastInventory = true;
        }
    }

    exportInventory() {
        this.route.params.subscribe(params => {
            const inventoryId = + params['id'];
            this.inventoriesService.exportInventory(inventoryId).subscribe(data => { 
              this.downloadFileService.downloadFile(data);
            });
        });
    }

    revalidateInventory(){
        this.inventoriesService.revalidateInventory(this.inventory.referenceNumber).subscribe( data =>
            {
                if(data){
                    location.reload(); 
                }
            }
        );
    }

    

    setTableIconsByOrientation() {
        this.layoutDirection = this.configService.templateConf.layout.dir;
        this.pagerLeftArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-right' : 'datatable-icon-left';
        this.pagerRightArrowIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-left' : 'datatable-icon-right';
        this.pagerPreviousIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-skip' : 'datatable-icon-prev';
        this.pagerNextIcon = this.layoutDirection === 'rtl' ? 'datatable-icon-prev' : 'datatable-icon-skip';
    }

    onSaveEvent(serials) {     
        this.serial = [];
        serials.forEach(element => {
            this.serial.push(element.serialNumber);
        });
        this.inventoriesService.addSerialNumber(this.inventory.retailer.code.trim(), this.inventory.id, this.serial).subscribe(result => {           
            if(result) {
                if(result.received == result.created) {
                    location.reload();                
                } else if(result.errored > 0) {
                    var message = this.translateService.instant('Components.Inventories.error-saving');
                    if(result.details){
                        result.details.forEach(details => {
                           if(details.type === 'Error'){
                               if (details.id) message += details.id;
                               else message += details.description +"\n";
                           }
                        });
                    }                    
                    this.toastr.warning(message);           
                }   
            }                     
        });;
    }

    onDeleteEvent(serials) {     
        this.serial = [];
        serials.forEach(element => {
            this.serial.push(element.serialNumber);
        });
        this.inventoriesService.deleteSerialNumber(this.inventory.retailer.code.trim(), this.inventory.id, this.serial).subscribe(result => {           
            if(result) {
                if(result.received == result.created) {
                    location.reload();                
                } else if(result.errored > 0) {
                    var message = this.translateService.instant('Components.Inventories.error-saving');
                    if(result.details){
                        result.details.forEach(details => {
                           if(details.type === 'Error'){
                               if (details.id) message += details.id;
                               else message += details.description +"\n";
                           }
                        });
                    }                    
                    this.toastr.warning(message);           
                }   
            }                     
        });;
    }
    revertModalEvent(button){
        //const val = event.target.value.toLowerCase();
        
        this.isAddingSerial = true;
        this.isDeletingSerial = true;
    }

    showShipmentPopup(){
        const modalRef = this.modalService.open(ShipmentSerialNumberItemsTableComponent, this.ngbModalOptions);
        modalRef.componentInstance.retailerCode = this.retailerCode;        
    }
}

import { Component} from '@angular/core';
import { PrivacyBaseComponent } from '@src/base-components/privacy/privacy-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-page-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['../../../base-components/privacy/privacy-base.component.scss']
})
export class PrivacyComponent extends PrivacyBaseComponent{
    constructor(configService: ConfigService) {
        const params = {
            appName: 'eWarranty',
            companyName: 'Alpina Watches',
            products: 'watches',
            ContactEmail :'ewarranty@alpina-watches.com'
        }
        const logoUrl = '';
        super(params, logoUrl, configService);
    }
}
import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantyExtensionsService } from '@src/services/warranty/warrantyextensions.service';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-modal-return-extension',
        templateUrl: './modal-return-extension.component.html'
    })

export class ModalCancelExtensionComponent {

    comment: string;
    @Input() warranties: Warranty[];
    @Output() refreshWarrantiesExtension = new EventEmitter<Warranty[]>();

    constructor(@Inject('AuthService')
        protected authService: IAuthService,
        protected warrantiesService: WarrantyExtensionsService,
        public activeModal: NgbActiveModal        
    ) {}

    confirmCancelExtension(event) {

        if (event.detail > 1) {
            return;
        }
        const saleWarranty = cloneDeep(this.warranties.filter((w) => w.type === 'sale')[0]);
        this.warrantiesService.cancelExtension(saleWarranty.id)
            .subscribe((w) => {
                if (w) {
                    this.closeCancelExtensionConfirmation();  
                    this.warranties.forEach(element => {
                        element.warrantyExtensions.splice(element.warrantyExtensions.findIndex(a => a.warrantyId === saleWarranty.id) , 1)              
                    });               
                    this.refreshWarrantiesExtension.emit(this.warranties);                    
                } else {
                    // Something went wrong, user got notified already.
                    this.closeCancelExtensionConfirmation();
                }
            });
    }

    closeCancelExtensionConfirmation() {
        this.activeModal.close();
    }
}
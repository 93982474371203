import { Injectable, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { Metric } from '../../model/metric';
import * as moment from 'moment';
import { MetricWithLocation } from '../../model/metricWithLocation';
import { ExportedFile } from '../../model/exportedFile';
import { ExportSalesParameters } from '@src/model/filter-parameters/export-sales-parameters';

@Injectable()
export class DashboardService extends BaseService {

    protected baseUrl = environment.apiBaseUrl + '/api/sales';
    constructor(injector: Injector) {
        super(injector);
    }
    getTopRetailersMetrics(top: number, from = null, to = null) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics/topretailers?top=${top}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }
    getRetailersMetrics({ productId = null, familyId = null, marketId = null, retailerId = null, from = null, to = null }
        : {  productId?: number, familyId?: number,marketId?: number, retailerId?: number, from?: Date, to?: Date, entryId?:number } = {}) {
        const url = this.baseUrl;
        return this.get<MetricWithLocation[]>(`${url}/metrics/retailers?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}&` 
        );
    }

    getTopSellersMetrics(top: number, retailerId: number = null, from = null, to = null) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics/topsellers?top=${top}&${this.buildQueryParameters('retailerId', retailerId)}&` +
        `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
        `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }
    getSalesMetrics({ productId = null, familyId = null, retailerId = null, from = null, to = null, interval = '', marketId = null }
        : { productId?: number, familyId?: number, retailerId?: number, from?: Date, to?: Date, interval?: string, marketId?: number, }) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('interval', interval)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getSalesMetricsByTypes({ productId = null, familyId = null, retailerId = null, from = null, to = null, marketId = null }
        : { productId?: number, familyId?: number, retailerId?: number, from?: Date, to?: Date, marketId?: number, }) {
        const url = this.baseUrl;
        return this.get<Metric[]>(`${url}/metrics/types?` +
            `${this.buildQueryParameters('productId', productId)}&` +
            `${this.buildQueryParameters('familyId', familyId)}&` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('marketId', marketId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }

    getTotalActivations({ retailerId = null, from = null, to = null }
        : { retailerId?: number, from?: Date, to?: Date } = {}) {
        const url = this.baseUrl;
        return this.get<number>(`${url}/metrics/count?` +
            `${this.buildQueryParameters('retailerId', retailerId)}&` +
            `${this.buildQueryParameters('from', from == null ? null : moment(from).format('YYYY-MM-DD'))}&` +
            `${this.buildQueryParameters('to', to == null ? null : moment(to).format('YYYY-MM-DD'))}`
        );
    }
    getLast3MonthsActivations({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        const from = moment().add(-2, 'M').toDate();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(from.getUTCFullYear(), from.getUTCMonth(), 1),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
        });
    }

    getTotalActivationsThisYear({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), 0, 1),
            to: new Date(now.getUTCFullYear() + 1, 0, 1)
        });
    }
    getTotalActivationsThisMonth({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), now.getUTCMonth(), 1),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1)
        });
    }
    getTotalActivationsToday({ retailerId = null }
        : { retailerId?: number } = {}) {
        const now = new Date();
        return this.getTotalActivations({
            retailerId: retailerId,
            from: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()),
            to: new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1)
        });
    }
    exportSales(exportSales: ExportSalesParameters) {
        let url  = this.baseUrl + `/export?`;
        exportSales.productIds.forEach(element => {
            url = url + `${this.buildQueryParameters('productId', element)}&`
        });

        exportSales.familyIds.forEach(element => {
            url = url +`${this.buildQueryParameters('familyId', element)}&`
        });

        exportSales.operationsType.forEach(element => {
            url = url + `${this.buildQueryParameters('operationType', element)}&`;
            });

        url = url + `${this.buildQueryParameters('serialNumber', exportSales.serialNumber, )}&` +
        `${this.buildQueryParameters('warrantyCard', exportSales.warrantyCard, )}&` +
        `${this.buildQueryParameters('marketId', exportSales.marketId, )}&` +
        `${this.buildQueryParameters('retailerId', exportSales.retailerId, )}&` +
        `${this.buildQueryParameters('productSku', exportSales.productSku, )}&` +
        `${this.buildQueryParameters('isFake', exportSales.isFake, )}&` +
        `${this.buildQueryParameters('isDuplicate', exportSales.isDuplicate, )}&` +
        `${this.buildQueryParameters('from', exportSales.from == null ? null : moment(exportSales.from).format('YYYY-MM-DD'))}&` +
        `${this.buildQueryParameters('to', exportSales.to == null ? null : moment(exportSales.to).format('YYYY-MM-DD'))}&`+
        `${this.buildQueryParameters('parentNotificationId', exportSales.parentNotificationId, )}&`+
        `${this.buildQueryParameters('isWarrantyExtension', exportSales.isWarrantyExtension, )}`;

        return this.get<string>(url);
    }
}
import {environment} from '../../environments/environment'

var colors;

if (environment.clientName == 'frederique') {
    colors = [
        '#a69996',
        '#968b86',
        '#867d77',
        '#777069',
        '#67635b',
        '#58564e',
        '#494a42',
        '#3b3d37',
        '#2e322c',
        '#212621'
    ];
}
else if (environment.clientName == 'alpina') {
    colors = [
        '#b11632',
        '#9d1531',
        '#89152f',
        '#76152c',
        '#621528',
        '#4f1424',
        '#3c131e',
        '#2a1017',
        '#1a0a0f',
        '#000000'
    ];
}
else {
    colors = [
        '#009DA0',
        '#FF8D60',
        '#FF586B',
        '#0CC27E',
        '#1CBCD8',
        '#868e96',
        '#009DA0',
        '#FF8D60',
        '#AAAAAA',
        '#FF586B'
        ];
}

export const ChartColors = colors;


// export const ChartColors = [
//     '#009DA0',
//     '#FF8D60',
//     '#FF586B',
//     '#0CC27E',
//     '#1CBCD8',
//     '#868e96',
//     '#009DA0',
//     '#FF8D60',
//     '#AAAAAA',
//     '#FF586B'
// ];
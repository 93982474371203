import { DashboardComponent } from './dashboard.component';
import { DashboardSalesChartComponent } from './charts/sales-chart/dashboard-sales-chart.component';
import { DashboardActivationMetricsComponent } from './metrics/dashboard-activation-metrics.component';
import { DashboardActivationMetricsElementComponent } from './metrics/dashboard-activation-metrics-element.component';
import { DashboardDefaultFilterBaseComponent } from './dashboard-profiles/default/dashboard-default-filter-base.component';
import { DashboardDefaultNewFilterComponent } from './dashboard-profiles/default/new-filter/dashboard-default-new-filter.component';
import { DashboardDefaultFilterComponent } from './dashboard-profiles/default/filter/dashboard-default-filter.component';
import { DashboardDonutChartComponent } from './charts/donut-chart/dashboard-donut-chart-component';
import { DashboardRetailerFilterComponent } from './dashboard-profiles/retailer/filter/dashboard-retailer-filter.component';
import { DashboardRetailerNewFilterComponent } from './dashboard-profiles/retailer/new-filter/dashboard-retailer-new-filter.component';
import { DashboardRetailerFilterBaseComponent } from './dashboard-profiles/retailer/dashboard-retailer-filter-base.component';
import { DashboardDefaultComponent } from './dashboard-profiles/default/dashboard-default.component';
import { DashboardRetailerComponent } from './dashboard-profiles/retailer/dashboard-retailer.component';
import { DashboardBarChartComponent } from './charts/bar-chart/dashboard-bar-chart-component';
import { DashboardMapComponent } from './map/dashboard-map.component';

export const DashboardComponents = [
    DashboardComponent,
    DashboardSalesChartComponent,
    DashboardActivationMetricsComponent,
    DashboardActivationMetricsElementComponent,
    DashboardDefaultFilterBaseComponent,
    DashboardDefaultNewFilterComponent,
    DashboardDefaultFilterComponent,
    DashboardDonutChartComponent,
    DashboardRetailerFilterBaseComponent,
    DashboardRetailerFilterComponent,
    DashboardRetailerNewFilterComponent,
    DashboardDefaultComponent,
    DashboardRetailerComponent,
    DashboardBarChartComponent,
    DashboardMapComponent
];
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '@src/services/layout/layout.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html'
})

export class NavbarComponent {
    currentLang = 'en';
    isPersonalizedLanguage = false;
    toggleClass = 'ft-maximize';
    placement = "bottom-right";
    public isCollapsed = true;
    layoutSub: Subscription;
    private currentUser: UserProfile;

    constructor(
        public translate: TranslateService,
        @Inject('AuthService') private authService : IAuthService,
        private router: Router,
        private configService: ConfigService,
        private layoutService: LayoutService) {
        /* Language priority is the following:
        1) Set by this APP (Persisted LocalStorage)
        2) Use the browser language (No longer used / Disabled)
        3) Use the language read from the Profile Token (Identity Server)
        */
        let initialLanguage: string = localStorage.getItem('CurrentLanguage');
        if (!initialLanguage || initialLanguage.length === 0) {
            // const browserLang: string = translate.getBrowserLang();
            // initialLanguage = browserLang.match(/en|es|pt|de|fr/) ? browserLang : 'en';
            initialLanguage = 'en';
        } else {
            this.isPersonalizedLanguage = true;
        }
        translate.setDefaultLang('en');
        translate.use(initialLanguage);
        this.currentLang = 'en';
        this.configService.initConfigValue();
        this.setOrientationByLanguage(initialLanguage);

        this
        .authService
        .getCurrentUser()
        .subscribe(v => {
          this.currentUser = v;
          this.UpdateLanguageFromUserProfile();
        });
    }

    private UpdateLanguageFromUserProfile() {
        // If the current language is the default one (not read from local storage),
        // read it from the Profile Token and save it to local storage for future requests.
        if (this.currentUser) {
            const locale = this.currentUser.locale;
            if (locale && locale.length > 0) {
                this.ApplyLanguage(locale);
            }
        }
    }
    private ApplyLanguage(language: string) {
        localStorage.setItem('CurrentLanguage', language);
        this.currentLang = language;
        this.translate.use(language);
        this.setOrientationByLanguage(language);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        } else {
            this.toggleClass = 'ft-maximize';
        }
    }
    get userDisplayName() : string {
        return this.currentUser == null ? '' : this.currentUser.name;
    }

    onLogOut() {
        this.authService.logOut();
    }

    get isExternalPage() : boolean {
        return this.configService.templateConf.isPublicPage;
    }

    setOrientationByLanguage(language: string) {
        if (language === 'ar') {
            this.configService.setOrientationConfig('rtl');
            this.configService.placement = 'bottom-left';
        } else {
            this.configService.setOrientationConfig('ltr');
            this.configService.placement = 'bottom-right';
        }
    }
}

import { Component, Input} from '@angular/core';
import { environment } from '../../../environments/environment';
@Component({
    selector: 'app-warning-message-find-result',
    templateUrl: './warning-message-find-result.component.html'
})
export class WarningMessageFindResultComponent {
    IsFCMessage: boolean;

    constructor() {
        this.IsFCMessage = environment.clientName == "frederique" || environment.clientName == "alpina";
    }
}
import { Component, OnInit, Inject } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserRolesEnum } from '@src/model/userRoles';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent implements OnInit {
  loadRetailerProfile?: boolean = null;
  isBetaVersion: boolean;
  hasWarningMessage: boolean;
  
  constructor(@Inject('AuthService')
    private authService: IAuthService,
    private configService: ConfigService) { }
  ngOnInit() {
    this.authService.getCurrentUser()
      .subscribe(u =>
        this.loadRetailerProfile = u.isInRole(UserRolesEnum.RETAILER));

    this.isBetaVersion = this.configService.templateConf.isBetaVersion;
    this.hasWarningMessage = this.configService.templateConf.hasWarningMessage;
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../model/user';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { UserLocale } from '../../model/userLocale';
import { Role } from '@src/model/role';
import { HttpResponse } from '@angular/common/http';
import { KeyValueTypedPair } from '@src/model/productOrFamilyName';
import { UsersParameters } from '@src/model/filter-parameters/user-parameters';

@Injectable()
export class UsersService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/users';
  constructor(injector: Injector) {
    super(injector);
  }
  resetUserPassword(userName: string) {
    const url = this.baseUrl;
    return this.get<User>(`${url}/reset/${userName}`);
  }
  getUsers(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<any>(`${url}/queries/?` +
      `${this.buildQueryParameters('queryTerm', queryTerm)}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }
  getMyUser(): Observable<User> {
    const url = this.baseUrl;
    return this.get<User>(`${url}/me`);
  }
  acceptPrivacyPolicy() {
    const url = `${this.baseUrl}/me/privacypolicy`;
    return this.put(url, null);
  }
  getUser(id: string): Observable<User> {
    const url = this.baseUrl;
    return this.get<User>(`${url}/${id}`);
  }
  updateUser(id: string, user: User) {
    const url = this.baseUrl;
    return this.put<User>(`${url}/${id}`, user);
  }
  createUser(user: User) {
    const url = this.baseUrl;
    return this.post<User>(url, user);
  }
  updateMyLocale(locale: UserLocale) {
    const url = this.baseUrl;
    return this.put<UserLocale>(`${url}/me/locale`, locale);
  }
  isUserNameFree(userName: string) {
    const url = this.baseUrl;
    return this.get<boolean>(`${url}/usernames/${userName}`);
  }
  findSellerIdByUserName(userName: string) {
    const url = this.baseUrl;
    return this.get<string>(`${url}/roles/seller/users/${userName}`);
  }
  getRolesByUserRole(role: string): Observable<Role[]> {
    const url = this.baseUrl;
    return this.get<Role[]>(`${url}/roles/byUserRole?role=${role}`);
  }

  getUsersSuggestions(q: string, select1: string, select2: string = null): Observable<KeyValueTypedPair[]> {
    const currentLang = this.translateService.getDefaultLang();
    const filterUrl = `${this.baseUrl}/filters?` +
      `${this.buildQueryParameters('q', q )}&` +
      `${this.buildQueryParameters('lang', currentLang )}&` +
      `${this.buildQueryParameters('select', select1 )}&` +
      (select2 !== undefined ? `${this.buildQueryParameters('select', select2 )}` : '');

    return this.get<KeyValueTypedPair[]>(filterUrl);
  }
  exportUsers(userParameters: UsersParameters): Observable<string> {
    const url = `${this.baseUrl}/export?` +
      `${this.buildQueryParameters('firstname', userParameters.firstName, )}&` +
      `${this.buildQueryParameters('lastname', userParameters.lastName, )}&` +
      `${this.buildQueryParameters('username', userParameters.userName, )}&` +
      `${this.buildQueryParameters('email', userParameters.email, )}&` +
      `${this.buildQueryParameters('role', userParameters.role, )}&` +
      `${this.buildQueryParameters('isActive', userParameters.active, )}&` +
      `${this.buildQueryParameters('isInactive', userParameters.inactive, )}&`+
      `${this.buildQueryParameters('parentNotificationId', userParameters.parentNotificationId, )}`;

      return this.get<string>(url);
  }

  filterUsers(usersParameters: UsersParameters, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/filter?` +
      `${this.buildQueryParameters('firstname', usersParameters.firstName, )}&` +
      `${this.buildQueryParameters('lastName', usersParameters.lastName, )}&` +
      `${this.buildQueryParameters('email', usersParameters.email, )}&` +
      `${this.buildQueryParameters('userName', usersParameters.userName, )}&` +
      `${this.buildQueryParameters('role', usersParameters.role, )}&` +
      `${this.buildQueryParameters('isActive', usersParameters.active, )}&` +
      `${this.buildQueryParameters('isInactive', usersParameters.inactive, )}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`;

      return this.getWithHeader<User[]>(url);
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Sale } from '../../model/sale';
import { environment } from '../../environments/environment';
import { BaseService } from '../base.service';
import { HttpHeaders } from '@angular/common/http';
import { Product } from '@src/model/product';

@Injectable()
export class ExternalService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/external';
  constructor(injector: Injector) {
    super(injector);
  }

  getAfterSale(searchTerm: string): Observable<Sale[]> {
    const url = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.get<Sale[]>(`${url}/aftersale?` +
      `${this.buildQueryParameters('searchTerm', searchTerm)}`, httpOptions);
  }

  getProductDetails(productSku: string): Observable<Product> {
    const url = this.baseUrl;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.get<Product>(`${url}/product/${productSku}`, httpOptions);
  }
}

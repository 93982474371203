import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { Observable } from 'rxjs';
import { Retailer } from '../../model/retailer';
import { User } from '../../model/user';
import { environment } from '../../environments/environment';
import { KeyValueTypedPair } from '../../model/productOrFamilyName';
import { RootObject } from '@src/model/mapbox/rootObject';
import { Settings } from '@src/model/settings';
import { Channel } from '@src/model/channel';
import { RetailersParameters } from '@src/model/filter-parameters/retailers-parameters';
import { ChannelOptions } from '@src/model/channelOptions';
import { UrlData } from '@src/model/urlData';
import * as moment from 'moment';
import { PosDetails } from '@src/model/posDetails';

@Injectable()
export class RetailersService extends BaseService {

  protected baseUrl = environment.apiBaseUrl + '/api/retailers';
  protected mapboxBaseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
  constructor(injector: Injector) {
    super(injector);
  }
  getRetailers(): Observable<Retailer[]> {
    return this.get<Retailer[]>(this.baseUrl);
  }
  getActiveRetailers(): Observable<Retailer[]> {
    return this.get<Retailer[]>(`${this.baseUrl}/active`);
  }
  queryRetailers(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<Retailer[]>(`${url}/queries/?` +
      `${this.buildQueryParameters('queryTerm', queryTerm)}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  queryActiveRetailers(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<Retailer[]>(`${url}/queries/?active=true&` +
      `${this.buildQueryParameters('queryTerm', queryTerm)}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  getRetailerUsers(retailerId: number, roles: string[] = []): Observable<User[]> {
    let url = this.baseUrl + `/${retailerId}/users`;
    if (roles.length > 0) {
      const rolesq = roles.map(r => 'roles=' + r).join('&');
      url = url + `?${rolesq}`;
    }
    return this.get<User[]>(url);
  }
  getRetailersByMarketId(marketId : number) : Observable<Retailer[]>{
    const url = this.baseUrl + `/markets/${marketId}`;
    return this.get<Retailer[]>(url);
  }

  getRetailersByMarketChildrenId(marketId : number) : Observable<Retailer[]>{
    const url = this.baseUrl + `/marketsChildren/${marketId}`;
    return this.get<Retailer[]>(url);
  }
  getRetailerById(retailerId: number): Observable<Retailer> {
    const url = this.baseUrl + `/${retailerId}`;
    return this.get<Retailer>(url);
  }
  getMyRetailer(): Observable<Retailer> {
    const url = this.baseUrl + `/my`;
    return this.get<Retailer>(url);
  }

  getRepairRetailer(): Observable<Retailer> {
    const url = this.baseUrl + `/repair`;
    return this.get<Retailer>(url);
  }

  getRetailersByUser(userId: string): Observable<any> {
    const url = this.baseUrl + `/users/${userId}`;
    return this.getWithHeader<Retailer[]>(url);
  }

  updateRetailer(retailer: Retailer): Observable<Retailer> {
    const url = this.baseUrl + `/${retailer.id}`;
    return this.put<Retailer>(url, retailer);
  }

  addSellerToRetailer(retailerId: number, user: User) {
    const url = this.baseUrl + `/${retailerId}/sellers`;
    return this.post<boolean>(url, user);
  }
  removeUserFromRetailer(retailerId: number, userId: string) {
    const url = this.baseUrl + `/${retailerId}/users/${userId}`;
    return this.delete<boolean>(url);
  }
  getRetailersSuggestions(q: string, select1: string, select2: string = null, select3: string = null): Observable<KeyValueTypedPair[]> {
    const currentLang = this.translateService.getDefaultLang();
    const filterUrl = `${this.baseUrl}/filters?` +
      `${this.buildQueryParameters('q', q )}&` +
      `${this.buildQueryParameters('lang', currentLang )}&` +
      `${this.buildQueryParameters('select', select1 )}&` +
      (select2 !== undefined ? `${this.buildQueryParameters('select', select2 )}&` : '') +
      (select3 !== undefined ? `${this.buildQueryParameters('select', select3 )}` : '');

    return this.get<KeyValueTypedPair[]>(filterUrl);
  }
  getMapboxAdresseSuggestions(searchText: string, apiToken: string): Observable<RootObject> {
    const url = this.mapboxBaseUrl + `/${searchText}.json?limit=${5}&access_token=${apiToken}`;
    return this.getMapboxAddesses<RootObject>(url);
  }
  getSettingsByRetailerId(retailerId: number): Observable<Settings[]> {
    const url = this.baseUrl + `/${retailerId}/settings`;
    return this.get<Settings[]>(url);
  }

  getPosDetails(): Observable<PosDetails> {
    const url = this.baseUrl + `/posDetails`;
    return this.get<PosDetails>(url);
  }

  getChannelsByRetailerId(retailerId: number): Observable<Channel[]> {
    const url = this.baseUrl + `/${retailerId}/channels`;
    return this.get<Channel[]>(url);
  }
  getChannelOptionByRetailerId(retailerId: number): Observable<ChannelOptions[]> {
    const url = this.baseUrl + `/${retailerId}/channelOptions`;
    return this.get<ChannelOptions[]>(url);
  }
  getStatUrl(trackingKey: string, readerId: string, language: string): Observable<any> {
    const url = this.baseUrl;
    return this.get<UrlData>(`${url}/staturl/?` +
      `${this.buildQueryParameters('trackingKey', trackingKey)}&` +
      `${this.buildQueryParameters('readerId', readerId)}&` +
      `${this.buildQueryParameters('language', language)}`);
  }
  checkIfExtStatIsEnabled(){
    const url = this.baseUrl + `/isEnabled`;
    return this.get<boolean>(url);
  }
  
  filterRetailers(retailersParameters: RetailersParameters, sort: string, pageSize: number, page: number): Observable<any> {
    const url = `${this.baseUrl}/filter?` +
      `${this.buildQueryParameters('name', retailersParameters.name, )}&` +
      `${this.buildQueryParameters('code', retailersParameters.code, )}&` +
      `${this.buildQueryParameters('marketId', retailersParameters.marketId, )}&` +
      `${this.buildQueryParameters('businessGroup', retailersParameters.businessGroup, )}&` +
      `${this.buildQueryParameters('city', retailersParameters.city, )}&` +
      `${this.buildQueryParameters('isActive', retailersParameters.active, )}&` +
      `${this.buildQueryParameters('isInactive', retailersParameters.inactive, )}&` +
      `${this.buildQueryParameters('hasInventories', retailersParameters.hasInventories, )}&` +
      `${this.buildQueryParameters('inventoriesFrom', retailersParameters.inventoriesFrom == null ? null : moment(retailersParameters.inventoriesFrom).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('inventoriesTo', retailersParameters.inventoriesTo == null ? null : moment(retailersParameters.inventoriesTo).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`;

      return this.getWithHeader<Retailer[]>(url);
  }

  exportRetailers(retailersParameters: RetailersParameters): Observable<string> {
    const url = `${this.baseUrl}/export?` +
      `${this.buildQueryParameters('name', retailersParameters.name, )}&` +
      `${this.buildQueryParameters('code', retailersParameters.code, )}&` +
      `${this.buildQueryParameters('marketId', retailersParameters.marketId, )}&` +
      `${this.buildQueryParameters('city', retailersParameters.city, )}&` +
      `${this.buildQueryParameters('isActive', retailersParameters.active, )}&` +
      `${this.buildQueryParameters('isInactive', retailersParameters.inactive, )}&` +
      `${this.buildQueryParameters('hasInventories', retailersParameters.hasInventories, )}&` +
      `${this.buildQueryParameters('inventoriesFrom', retailersParameters.inventoriesFrom == null ? null : moment(retailersParameters.inventoriesFrom).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('inventoriesTo', retailersParameters.inventoriesTo == null ? null : moment(retailersParameters.inventoriesTo).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('parentNotificationId', retailersParameters.parentNotificationId, )}`;

      return this.get<string>(url);
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Sale } from '../../model/sale';
import { SaleDetails } from '../../model/saleDetails';
import { environment } from '../../environments/environment';
import { BaseService } from '../../services/base.service';
import { KeyValueTypedPair } from '@src/model/productOrFamilyName';
import { SalesParameters } from '@src/model/filter-parameters/sales-parameters';
import * as moment from 'moment';
import { WarrantyExtensionSettings } from '@src/model/config/WarrantyExtensionSettings';

@Injectable()
export class SalesService extends BaseService {
  protected baseUrl = environment.apiBaseUrl + '/api/sales';
  constructor(injector: Injector) {
    super(injector);
  }
  querySales(queryTerm: string, sort: string, pageSize: number, page: number): Observable<any> {
    const url = this.baseUrl;
    return this.getWithHeader<Sale[]>(`${url}/queries?` +
      `${this.buildQueryParameters('queryTerm', queryTerm, )}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}`);
  }

  filterSales(salesParameters: SalesParameters, sort: string, pageSize: number, page: number): Observable<any> {
    let url = `${this.baseUrl}/filters?` +
      `${this.buildQueryParameters('serialNumber', salesParameters.serialNumber, )}&` +
      `${this.buildQueryParameters('warrantyCard', salesParameters.warrantyCard, )}&` +
      `${this.buildQueryParameters('marketId', salesParameters.marketId, )}&` +
      `${this.buildQueryParameters('retailerId', salesParameters.retailerId, )}&` +      
      `${this.buildQueryParameters('businessGroup', salesParameters.businessGroup, )}&` +
      `${this.buildQueryParameters('productSku', salesParameters.productSku, )}&` +
      `${this.buildQueryParameters('isFake', salesParameters.isFake, )}&` +
      `${this.buildQueryParameters('hasD2CExtension', salesParameters.hasD2CExtension, )}&` +
      `${this.buildQueryParameters('isDuplicate', salesParameters.isDuplicate, )}&` +
      `${this.buildQueryParameters('from', salesParameters.from == null ? null : moment(salesParameters.from).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('to', salesParameters.to == null ? null : moment(salesParameters.to).format('YYYY-MM-DD'))}&` +
      `${this.buildQueryParameters('sort', sort)}&` +
      `${this.buildQueryParameters('pageSize', pageSize.toString())}&` +
      `${this.buildQueryParameters('page', page.toString())}&` +
      `${this.buildQueryParameters('isWarrantyExtension', salesParameters.isWarrantyExtension, )}&`;

      salesParameters.operationsType.forEach(element => {
        url = url + `${this.buildQueryParameters('operationType', element)}&`;
      });

      return this.getWithHeader<Sale[]>(url);
  }

  getSaleDetails(id: number, isSearch): Observable<SaleDetails> {
    const url = this.baseUrl;
    return this.get<SaleDetails>(`${url}/${id}/details?isSearch=${isSearch}`);
  }

  getAfterSale(searchTerm: string) : Observable<Sale> {
    const url = this.baseUrl;
    return this.get<Sale>(`${url}/aftersale?` +
    `${this.buildQueryParameters('searchTerm', searchTerm)}`);
  }

  getSalesBySerialNumberOrWarrantyCard(searchTerm: string) : Observable<Sale[]> {
    const url = this.baseUrl;
    return this.get<Sale[]>(`${url}/search?` +
    `${this.buildQueryParameters('searchTerm', searchTerm)}`);
  }

  getSerialNumbersWarrantyCardsNames(q: string): Observable<KeyValueTypedPair[]> {
    return this.get<KeyValueTypedPair[]>(`${this.baseUrl}/suggestions?q=${q}&_select=serialNumber&_select=warrantycardcode`);
  }

  getWarrantyExtensionSetting(): Observable<WarrantyExtensionSettings> {
    return this.get<WarrantyExtensionSettings>(`${this.baseUrl}/WarrantyExtensionSetting`);
  }
}

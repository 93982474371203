import { Component, Input } from '@angular/core';
import { ProductItem } from '@src/model/productItem';

@Component({
    selector: 'app-sale-details-product-info',
    templateUrl: './sale-details-product-info.component.html',
    styleUrls: ['./sale-details-product-info.component.scss']
})

export class SaleDetailsProductInfoComponent {
    isCollapsed: boolean;
    @Input() productItem = new ProductItem();
    @Input() isReturned: boolean;
    @Input() isFake: boolean;
    @Input() isCpo: boolean;
    @Input() isDuplicate: boolean;    
    @Input() isValid: boolean;
}

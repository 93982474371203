import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { IAuthService } from '@src/auth/auth.service.interface';
import { Warranty } from '@src/model/warranty/warranty';
import { WarrantyUpdateWithComment } from '@src/model/warranty/WarrantyUpdateWithComment';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { Sale } from '@src/model/sale';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { Router } from '@angular/router';
import { AppSettings } from '@src/core/AppSettings';

declare var require: any;
const cloneDeep  = require('lodash/cloneDeep');

@Component(
    {
        selector: 'app-modal-return-sale',
        templateUrl: './modal-return-sale.component.html'
    })

export class ModalReturnSaleComponent {

    comment: string;
    @Input() warranties: Warranty[];
    @Input() sale: Sale;
    @Input() operations: WarrantyOperation[];
    @Input() editEnabled: boolean;
    @Input() returnSaleEnabled: boolean;
    @Input() correctWarrantyEnabled: boolean;
    @Output() refreshWarranties = new EventEmitter<Warranty[]>();
    @Output() refreshReturnSaleButton = new EventEmitter<boolean>();
    @Input() refurbished : boolean;

    constructor(@Inject('AuthService')
        protected authService: IAuthService,
        protected warrantiesService: WarrantiesService,
        public activeModal: NgbActiveModal,
        protected router: Router,
        protected appSettings : AppSettings,
        protected calendar: NgbCalendar
    ) {}

    confirmCancelSale(event) {

        if (event.detail > 1) {
            return;
        }

        const saleWarranty = cloneDeep(this.warranties.filter((w) => w.type === 'sale')[0]);
        saleWarranty.isReturned = true;
        
        const warrantyReturn: WarrantyUpdateWithComment = {
            warranty: saleWarranty,
            comment: this.comment,
            refurbished : this.refurbished
        };
        this.warrantiesService.updateWarrantyWithComment(warrantyReturn)
            .subscribe((w) => {
                if (w != null) {
                    w.isExpired = new Date() > new Date(w.warrantyEndDate);
                    w.type = 'sale';
                    w.seller = this.sale.seller;
                    w.retailer = this.sale.retailer;    
                    this.warranties = [w];
                    this.operations = w.warrantyOperations;
                    this.sale.isReturned = w.isReturned;
                    this.closeCancelSaleConfirmaton();
                    this.returnSaleEnabled = this.editEnabled = this.correctWarrantyEnabled = this.editEnabled = false;
                    this.refreshWarranties.emit(this.warranties);
                    this.refreshReturnSaleButton.emit(this.returnSaleEnabled);
                } else {
                    // Something went wrong, user got notified already.
                    this.closeCancelSaleConfirmaton();
                }
            });
    }

    closeCancelSaleConfirmaton() {
        this.activeModal.close();
    }

    setCommentCancel(event) {
        this.comment = event.target.value;
    }
}
import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component(
    {
        selector: 'app-modal-support-popup',
        templateUrl: './support-popup.component.html'
    })

export class SupportMessagePopUp {
  
    confirmationAction: Function;
    cancelAction: Function;
    classNameConfirmationButton: string;
    label: string;
    title: string;

    constructor(
        public activeModal: NgbActiveModal
    ) {}

    closeConfirmaton() {
        this.activeModal.close();
     }
}
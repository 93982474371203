import { CustomersComponent } from './customers.component';
import { CustomerDetailsComponent } from './customers-details/customer-details.component';
import { CustomerInfoDetailsComponent } from './customers-details/info/customer-info-details.component';
import { CustomerProductInfoDetailsComponent } from './customers-details/product/customer-product-info-details.component';

export const CustomersComponents = [
    CustomersComponent,
    CustomerDetailsComponent,
    CustomerInfoDetailsComponent,
    CustomerProductInfoDetailsComponent
];
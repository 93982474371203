import { Inject } from '@angular/core';
import { Component } from '@angular/core';
import { IAuthService } from '@src/auth/auth.service.interface';
import { SidebarComponent } from '@src/shared/sidebar/sidebar.component';
import { ROUTES } from './sidebar-routes.config';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { UsersService } from '@src/services/users/users.service';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { environment } from '@src/environments/environment';
import { EnvironmentNames } from '@src/environments/environments.name';
import { ConfigService } from '@src/services/layout/config.service';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SupportMessagePopUp } from './support-popup/support-popup.component';

@Component({selector: 'app-sidebar-alpina', templateUrl: './sidebarAlpina.component.html'})

export class SidebarAlpinaComponent extends SidebarComponent {

    public ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false
    };
    constructor(accessRules: UserAccessRoleRules,
        public usersService: UsersService,
         public retailersService: RetailersService,
         public modalService: NgbModal,      
        @Inject('AuthService') authService : IAuthService,
        public configService: ConfigService) {
        super(accessRules, authService, usersService, retailersService, configService);

        this.RoutesInfo = ROUTES;
        this.Title = 'EWARRANTY';
        this.LogoUrl = '/assets/alpina-icon.png';
    }

    showSupportPopup(){       
        const modalRef = this.modalService.open(SupportMessagePopUp, this.ngbModalOptions);
        //SupportMessagePopUp.prototype.title = 'Support';      
    }
}

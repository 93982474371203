export const metricsactivationdata = [
    {
        'value': 4,
        'label': 'Manual Entry'
    },
    {
        'value': 50,
        'label': 'Automatic entry'
    },
    {
        'value': 10,
        'label': 'Duplication'
    }
];
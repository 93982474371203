import { Component } from '@angular/core';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})

export class HelpComponent {
    constructor(configService: ConfigService) {
        configService.setIsPublicPage(true);
    }
}
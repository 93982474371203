import { Component } from '@angular/core';
import { AssistanceBaseComponent } from '@src/base-components/assistance/assistance-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-page-assistance',
    templateUrl: '../../../base-components/assistance/assistance-base.component.html',
    styleUrls: ['../../../base-components/assistance/assistance-base.component.scss']
})
export class AssistanceComponent extends  AssistanceBaseComponent{
    constructor(configService: ConfigService) {
        const params = {
            appName: 'eWarranty',
            companyName: 'Alpina Watches',
            products: 'watches',
            logo : 'assets/alpina-icon.png',
            ContactEmail : 'warranty@alpinawatches.com'
        }
        const logoUrl = params.logo;
        super(params, logoUrl, configService);
    }
}
export class ExportSalesParameters {
    from: Date;
    to: Date;
    marketId?: number;
    retailerId?: number;
    productSku?: string;
    serialNumber?: string;
    warrantyCard?: string;
    operationsType?: number[] = [];
    isFake?: boolean;
    isDuplicate?: boolean;
    productIds?: number[] = [];
    familyIds?: number[] = [];
    parentNotificationId? : number; 
    isWarrantyExtension?: boolean;
}